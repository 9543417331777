<template>
  <!-- TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
    TODO: Decide if this component is removed  when experiment is finished -->
  <div
    :class="$style.content"
    data-testid="subscriptionAlertCard"
    @click="goToPlans"
  >
    <h6
      :class="$style.title"
      data-testid="subscriptionAlertMsg"
    >
      {{ trialExpiresText }}
    </h6>
    <XLink
      small
      :data-testid="dataTestid"
      @click="goToPlans"
    >
      {{ $t('dashboard.trialSubscriptionAlert.simplifiedSubscribe') }}
      <XIcon
        right
        icon="arrow-right"
      />
    </XLink>
  </div>
</template>

<script>
import { useGA, useBreakpoints } from '@base/hooks';
import { useI18n } from '@/locales';
import { defineComponent, computed } from 'vue';
import useHotjar from '@/modules/base/hooks/useHotjar';

export default defineComponent({
  name: 'TobBarSubscriptionAlert',
  props: {
    account: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { t } = useI18n();

    const trialExpiresText = computed(() => {
      if (props.account.enabled) {
        return props.account.trialExpiresText;
      }
      return t('dashboard.trialSubscriptionAlert.expired');
    });

    const dataTestid = computed(() =>
      props.account.trialEnding
        ? 'expiredGoToPlansTrialExperimentButton'
        : 'activeGoToPlansTrialExperimentButton'
    );

    const goToPlans = () => {
      useGA('event', 'buttonNewCheckoutClick', 'menu-app');
      useHotjar('event', 'navNewContractPlan');
      window.location.href = props.link;
    };

    return {
      goToPlans,
      dataTestid,
      trialExpiresText,
      ...useBreakpoints(),
    };
  },
});
</script>
<style lang="scss" module>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: calc(-1 * var(--space-large));

  // Negative margin to centralize and compensate for the gap defined in the TheHeader component

  .title {
    display: none;
    margin-right: var(--space-small-xx);
    margin-bottom: 0;
    text-transform: uppercase;
    color: var(--color-brand-secondary-400);

    @include breakpoint('extra-large') {
      display: block;
    }
  }
}
</style>
