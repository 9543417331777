<template>
  <TheHeader
    v-if="account.user"
    :class="$style.header"
    :hide-account-option="account.user.workspaceEnabled"
    :show-logo="!closeLink.show && showLogo"
  >
    <template #left>
      <HeaderMenuButton
        v-show="showActions"
        :class="$style.menuButton"
        @toggle="$emit('toggle-sidebar')"
      />

      <HeaderSearchForm
        v-if="showActions"
        :class="$style.searchForm"
        @search="filterNodes"
      />

      <XLink
        v-if="closeLink.show"
        id="layout-back-button"
        :to="closeLink.path"
        :data-testid="closeLink.dataTestid"
        no-padding
        :icon="!isMobile ? closeLink.icon : ''"
        @click.native="handleCloseLinkClick"
      >
        <XIcon
          v-if="isMobile"
          :icon="closeLink.icon"
          size="xl"
        />
        {{ closeLink.text }}
      </XLink>
    </template>

    <template #right>
      <HeaderHeadwayWidget
        v-if="!$route.meta.hideHeadway && showHeadwayWidget"
        :class="$style.updates"
      />
      <template v-if="!$route.meta.hideHubspot">
        <HeaderHubspotWidget :is-national-user="isNationalUser" />
      </template>
    </template>

    <template #down>
      <SubscriptionAlert
        v-if="!$route.meta.hideTrial && showTrialBadge"
        :account="account"
        :link="subscribeLink"
      />
    </template>
  </TheHeader>
</template>

<script>
import { mapGetters } from 'vuex';
import { isNavigationFailure } from 'vue-router';
import { useGA, useBreakpoints } from '@base/hooks';
import { useVerifyLocation } from '@account/composables';
import HeaderMenuButton from '@base/components/layout-default/header/HeaderMenuButton.vue';
import HeaderSearchForm from '@base/components/layout-default/header/HeaderSearchForm.vue';
import HeaderHeadwayWidget from '@base/components/layout-default/header/HeaderHeadwayWidget.vue';
import HeaderHubspotWidget from '@base/components/layout-default/header/HeaderHubspotWidget.vue';
import TheHeader from '@base/components/layout-default/the-header/TheHeader.vue';
import SubscriptionAlert from '@account/components/trial/SubscriptionAlert/SubscriptionAlert.vue';
import { camelCase } from 'lodash';

export default {
  name: 'LayoutDefaultHeaderContainer',
  components: {
    HeaderMenuButton,
    HeaderSearchForm,
    HeaderHeadwayWidget,
    HeaderHubspotWidget,
    TheHeader,
    SubscriptionAlert,
  },
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return useBreakpoints();
  },
  computed: {
    ...mapGetters({
      account: 'info/getAccount',
      links: 'info/getLinks',
      environment: 'environment/getVariables',
    }),
    isMobile() {
      return this.breakpoints.extraSmall;
    },
    isNationalUser() {
      return useVerifyLocation().isNationalUser.value;
    },
    closeLink() {
      const { show, locale, icon, path, dataTestid } = this.$route.meta.closeLink || {};
      return {
        show: show || false,
        text: !this.isMobile ? this.$t(locale || 'layout.header.closeLink') : '',
        icon: icon || 'times',
        path: { name: path || 'dashboard' },
        dataTestid: dataTestid || 'backToHomeButton',
      };
    },
    showTrialBadge() {
      const isNotSandbox = !this.environment.sandbox;
      const isTrial = this.account.plan === 'trial';

      return isTrial && isNotSandbox && this.isNationalUser;
    },
    showHeadwayWidget() {
      return this.isNationalUser;
    },
    subscribeLink() {
      const { intendedPlan } = this.account;

      if (intendedPlan) {
        const selfPlanUrl = this.links?.selfPlans?.[camelCase(intendedPlan)];
        return selfPlanUrl || this.links.plans;
      }

      return this.links.plans;
    },
  },
  methods: {
    async filterNodes(params) {
      try {
        await this.$router.push({ path: this.links.folders, query: params });
      } catch (error) {
        if (isNavigationFailure(error) && error.name === 'NavigationDuplicated') return;

        throw error;
      }
    },
    handleCloseLinkClick() {
      const { gaEvent } = this.$route.meta.closeLink || {};
      if (gaEvent) {
        useGA('event', gaEvent.name, gaEvent.category, gaEvent.label);
      }
    },
  },
};
</script>

<style lang="scss" module>
.menuButton {
  height: auto;
  min-height: auto;
  margin-right: var(--space-large);
  padding: 0px;
}

@media (max-width: 768px) {
  .searchForm {
    order: 4;
    max-width: none;
  }
}
</style>
