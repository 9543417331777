<template>
  <!-- TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
    TODO: Decide if this component is removed  when experiment is finished -->
  <div :class="$style.search">
    <div :class="$style.container">
      <XFormField
        :class="$style.inputGroup"
        :focus-active="false"
      >
        <XTooltip
          :class="$style.tooltip"
          :disabled="isSearchTooltipIsDisabled"
        >
          <template #activator>
            <XTextInput
              id="query"
              v-model="query"
              icon="search"
              name="query"
              :placeholder="$t('layout.header.search.placeholder')"
              maxlength="255"
              autocomplete="off"
              data-testid="searchDocumentInput"
              grouped
              full-width
              @keyup.enter="handleSearchByEnter"
              @click="openSearchMenu"
            >
              <XButton
                v-if="breakpoints.medium"
                :class="[$style.filtersButton, $style[buttonClasses]]"
                model="plain"
                data-testid="searchFilterMenuButton"
                @click="handleFilterMenu"
              >
                <XIcon icon="sliders" />
              </XButton>
            </XTextInput>
          </template>
          {{
            // TOGGLE_PREVENT_INCIDENT_ADVANCED_SEARCH_ENABLED
            // remove ternary
            toggles.togglePreventIncidentAdvancedSearchEnabled
              ? $t('layout.header.search.disabled')
              : $t('layout.header.search.tip')
          }}
        </XTooltip>
      </XFormField>
      <HeaderSearchFilter
        v-on-clickaway="handleFilterMenu"
        :visible="showFilterMenu"
        @search="handleEmitSearch"
        @filters-payload="($e) => (filtersData = $e)"
      />
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import { isEmpty } from 'lodash';

import { useGA, useBreakpoints } from '@base/hooks';
import HeaderSearchFilter from './HeaderSearchFilter.vue';

export default {
  name: 'HeaderSearchForm',
  components: {
    HeaderSearchFilter,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    onClickaway,
  },
  setup() {
    return useBreakpoints();
  },
  data: () => ({
    query: '',
    showSearchMenu: false,
    showFilterMenu: false,
    filtersData: {},
  }),
  computed: {
    ...mapGetters({
      account: 'info/getAccount',
      links: 'info/getLinks',
      // TOGGLE_PREVENT_INCIDENT_ADVANCED_SEARCH_ENABLED
      // TODO: remove toggles
      toggles: 'info/getToggles',
    }),

    vcoConfig() {
      return {
        handler: () => {
          this.showSearchMenu = false;
        },
        isActive: this.showSearchMenu,
        middleware: this.vcoMiddleware,
      };
    },
    buttonClasses() {
      if (!this.showFilterMenu) {
        return 'isClosed';
      }

      if (!isEmpty(this.filtersData.filtersPayload) && this.account.advancedSearchEnabled) {
        return 'hasActiveFilters';
      }

      return 'isOpen';
    },
    isSearchTooltipIsDisabled() {
      return (this.query && this.query !== '') || this.showFilterMenu;
    },
  },
  watch: {
    '$route.query': {
      handler: 'setFilledQuery',
      deep: true,
    },
  },
  mounted() {
    this.setFilledQuery();
  },
  methods: {
    useGA,
    setFilledQuery() {
      this.query = window.location.pathname === this.links.folders ? this.$route.query.query : '';
    },
    handleSearchByEnter() {
      // TOGGLE_PREVENT_INCIDENT_ADVANCED_SEARCH_ENABLED
      // remove if
      if (this.toggles?.togglePreventIncidentAdvancedSearchEnabled) return;
      this.useGA('event', 'search-by-enter', 'search-documents');
      this.handleEmitSearch();
    },
    handleEmitSearch() {
      const { filtersPayload, hasError } = this.filtersData;
      const hasFilters = !isEmpty(filtersPayload);
      const filters = hasFilters && this.account.advancedSearchEnabled ? filtersPayload : {};

      if (this.query || !hasError) {
        const query = this.query || hasFilters ? this.query ?? '' : undefined;

        this.$emit('search', { query, ...filters });
        this.showSearchMenu = false;
        this.showFilterMenu = false;
      }
    },
    openSearchMenu() {
      // TOGGLE_PREVENT_INCIDENT_ADVANCED_SEARCH_ENABLED
      // remove if
      if (this.toggles?.togglePreventIncidentAdvancedSearchEnabled) return;
      this.showSearchMenu = true;
    },
    vcoMiddleware(event) {
      return event.target.dataset.testid !== 'searchDocumentInput';
    },
    handleFilterMenu() {
      // TOGGLE_PREVENT_INCIDENT_ADVANCED_SEARCH_ENABLED
      // remove if
      if (this.toggles?.togglePreventIncidentAdvancedSearchEnabled) return;
      this.showFilterMenu = !this.showFilterMenu;
    },
  },
};
</script>

<style lang="scss" module>
.search {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 280px;

  .container {
    position: relative;
    width: 100%;

    .inputGroup {
      width: 100%;

      @include breakpoint('medium') {
        width: fit-content;
      }
    }

    @include breakpoint('medium') {
      max-width: 280px;
    }
  }

  .filtersButton {
    &.hasActiveFilters {
      background-color: var(--color-brand-primary-200) !important;
    }
  }
}

.tooltip {
  flex-grow: 1;
}

.icon {
  flex-shrink: 0;
  margin-left: var(--space-regular);
  color: var(--color-neutral-800);
}
</style>
