<template>
  <div>
    <div
      v-for="(group, index) in groups"
      :key="index"
    >
      <SidebarAccordionGroup
        v-if="showGroupItem(group)"
        :bounces="bounces"
        :downloads="downloads"
        :group="group"
        :links="_.get(links, group.name)"
        @save-group="saveGroup"
      />
    </div>
  </div>
</template>

<script>
import { useVerifyLocation } from '@account/composables';
// TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
// TODO: Decide if useTrialExperiment import should be removed
import { useTrialExperiment } from '@base/composables';
import { LAYOUT_CONSTANTS } from '@base/constants';
import SidebarAccordionGroup from '../../components/layout-default/sidebar/SidebarAccordionGroup.vue';

export default {
  name: 'SidebarAccordionContainer',
  components: {
    SidebarAccordionGroup,
  },
  props: {
    bounces: {
      type: Number,
      default: 0,
    },
    downloads: {
      type: Object,
      default: () => ({}),
    },
    links: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      groups: LAYOUT_CONSTANTS.NAVIGATION,
      groupsOpen: null,
    };
  },
  computed: {
    isNationalUser() {
      return useVerifyLocation().isNationalUser.value;
    },
    // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
    // TODO: Decide if isTrialExperiment should be removed
    isTrialExperiment() {
      return useTrialExperiment().isTrialExperiment.value;
    },
  },
  watch: {
    groups: {
      handler() {
        this.handleGroups();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    saveGroup(group) {
      const groupsSaved = localStorage.getItem('groups');
      if (groupsSaved) {
        localStorage.removeItem('groups');
        this.groupsOpen.set(group.name, group);
      }
      localStorage.setItem('groups', JSON.stringify(Array.from(this.groupsOpen)));
    },
    handleGroups() {
      const groupsSaved = localStorage.getItem('groups');
      if (groupsSaved) {
        this.groupsOpen = new Map(JSON.parse(groupsSaved));
        this.groupsOpen.forEach(({ name, open }) => {
          this.groups[name].open = open;
        });
      } else {
        const groups = Object.keys(this.groups);
        const groupsOpen = new Map();
        groups.forEach((group) => {
          const groupValue = { name: group, open: _.get(this.groups, `${group}.open`) };
          groupsOpen.set(group, groupValue);
        });
        this.groupsOpen = groupsOpen;
      }
    },
    showGroupItem(group) {
      // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
      // TODO: Decide if if below should be removed
      if (this.isTrialExperiment) {
        return !group.hideToTrialExperiment;
      }

      // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
      /* TODO: Decide if isTrialExperiment
        and showOnlyToTrialExperiments should be removed */
      if (this.isNationalUser && !group.showOnlyToTrialExperiment) return true;

      // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
      // TODO: Decide if showOnlyToTrialExperiment should be removed
      return !group.hideToForeignUser && !group.showOnlyToTrialExperiment;
    },
  },
};
</script>
