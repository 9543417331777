<script>
import PageError404 from '@modules/errors/PageError404.vue';

export default {
  name: 'BaseErrorLayout',
  components: {
    PageError404,
  },
};
</script>

<script setup>
function shouldLoadDefaultError404(route) {
  return !route.matched[0]?.components?.default;
}
</script>

<template>
  <main :class="$style.errorContainer">
    <PageError404 v-if="shouldLoadDefaultError404($route)" />
    <router-view
      v-else
      :key="$route.fullPath"
    />
  </main>
</template>

<style lang="scss" module>
.errorContainer {
  transition: all ease var(--time-transition-base);
}
</style>
