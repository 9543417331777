<template>
  <XButton
    model="icon"
    design="link"
    data-testid="helpBaloonButton"
    @click="handleHubspot"
  >
    <XIcon
      size="lg"
      icon="comment"
    />
  </XButton>
</template>

<script>
import { loadHubspot, openHubspotChat } from '@base/utils/hubspot';
import { useGA } from '@base/hooks';
import { defineComponent } from 'vue';
import { useHubspotHelpForm } from '@operators/composables/useHubspotHelpForm';

export default defineComponent({
  name: 'HeaderHubspotWidget',
  props: {
    isNationalUser: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    loadHubspot();
    const { openHubspotHelpForm } = useHubspotHelpForm();

    const handleHubspot = () => {
      useGA('event', 'buttonHelpIconClick', 'menu-app');

      if (props.isNationalUser) {
        openHubspotChat();
        return;
      }

      openHubspotHelpForm();
    };

    return { handleHubspot };
  },
});
</script>
